import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { DeactivationService } from "src/app/services/deactivation.service";
import { Observable, Subject } from "rxjs";
import { DragDropComponent } from "src/app/components/widgets/drag-drop/drag-drop.component";
import { GuidanceComponent, Resource } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";

import { SubmissionService } from "src/app/services/submission.service";
import { Submission, UploadedFile } from "src/app/backend";

@Component({
    selector: 'app-clinical-studies',
    templateUrl: './clinical-studies.component.html',
    styleUrls: ['./clinical-studies.component.scss'],
    imports: [
        CommonModule,
        DragDropComponent,
        GuidanceComponent,
        MatButtonModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        RouterModule,
        StepComponent
    ]
})
export class ClinicalStudiesComponent implements OnInit, OnDestroy, Deactivatable {

    resourceBundleClinicalStudies: Resource[] = [
        {
            icon: 'info',
            name: '1337 Changes-or-Modifications-During-the-Conduct-of-a-Clinical-Investigation--Final-Guidance-for-Industry-and-CDRH-Staff-May 2001(GA101).pdf',
            path: 'clinical_studies/1337 Changes-or-Modifications-During-the-Conduct-of-a-Clinical-Investigation--Final-Guidance-for-Industry-and-CDRH-Staff-May 2001(GA101).pdf'
        },
        {
            icon: 'info',
            name: '1668 HDE Q&A Mar2014 (RA203).pdf',
            path: 'clinical_studies/1668 HDE Q&A Mar2014 (RA203).pdf'
        },
        {
            icon: 'info',
            name: '17040 Humanitarian Device Exemption (HDE) program Sep2019 (RA202).pdf',
            path: 'clinical_studies/17040 Humanitarian Device Exemption (HDE) program Sep2019 (RA202).pdf'
        },
        {
            icon: 'info',
            name: '1757 Applying-Human-Factors-and-Usability-Engineering-to-Medical-Devices-2016-0216.pdf',
            path: 'clinical_studies/1757 Applying-Human-Factors-and-Usability-Engineering-to-Medical-Devices-2016-0216.pdf'
        },
        {
            icon: 'info',
            name: '1760 De Novo Classification Process (Eval of Automatic Class III Designation) 30Oct2017(RA201).pdf',
            path: 'clinical_studies/1760 De Novo Classification Process (Eval of Automatic Class III Designation) 30Oct2017(RA201).pdf'
        },
        {
            icon: 'info',
            name: '1782 IDEs for Early Feasibility Medical Device clinical Studies includinging FIH Oct2013 (RA202-RA203).pdf',
            path: 'clinical_studies/1782 IDEs for Early Feasibility Medical Device clinical Studies includinging FIH Oct2013 (RA202-RA203).pdf'
        },
        {
            icon: 'info',
            name: '1783 FDA-Decisions-for-Investigational-Device-Exemption-Clinical-Investigations-Aug 2014 (RA202).pdf',
            path: 'clinical_studies/1783 FDA-Decisions-for-Investigational-Device-Exemption-Clinical-Investigations-Aug 2014 (RA202).pdf'
        },
        {
            icon: 'info',
            name: '18011 Non_Clinical Laboratory Studies.pdf',
            path: 'clinical_studies/18011 Non_Clinical Laboratory Studies.pdf'
        },
        {
            icon: 'info',
            name: '7348.810 Chapt 48 Bioresearch Monitoring  2017-0417.pdf',
            path: 'clinical_studies/7348.810 Chapt 48 Bioresearch Monitoring  2017-0417.pdf'
        },
        {
            icon: 'info',
            name: '7348.811 Chapt 48 Bioresearch Monitoring- Clinical investigators and Sponsor Invesstigars  2020-0722.pdf',
            path: 'clinical_studies/7348.811 Chapt 48 Bioresearch Monitoring- Clinical investigators and Sponsor Invesstigars  2020-0722.pdf'
        },
        {
            icon: 'info',
            name: 'Acceptance-of-Clinical-Data-to-Support-Medical-Device-Applications-and-Submissions--Frequently-Asked-Questions---Guidance-for-Industry-and-Food-and-Drug-Administration-S.pdf',
            path: 'clinical_studies/Acceptance-of-Clinical-Data-to-Support-Medical-Device-Applications-and-Submissions--Frequently-Asked-Questions---Guidance-for-Industry-and-Food-and-Drug-Administration-S.pdf'
        },
        {
            icon: 'info',
            name: 'Adverse-Event-Reporting-to-IRBs-Guidance-Document Jan 2009 (RA203-SM605).pdf',
            path: 'clinical_studies/Adverse-Event-Reporting-to-IRBs-Guidance-Document Jan 2009 (RA203-SM605).pdf'
        },
        {
            icon: 'info',
            name: 'Changes-or-Modifications-During-the-Conduct-of-a-Clinical-Investigation-Final-Guidance-for-Industry-and-CDRH-Staff May 2001 (PM505).pdf',
            path: 'clinical_studies/Changes-or-Modifications-During-the-Conduct-of-a-Clinical-Investigation-Final-Guidance-for-Industry-and-CDRH-Staff May 2001 (PM505).pdf'
        },
        {
            icon: 'info',
            name: 'Charging for Investigational Products _ FDA Jan 1998 (SM602).pdf',
            path: 'clinical_studies/Charging for Investigational Products _ FDA Jan 1998 (SM602).pdf'
        },
        {
            icon: 'info',
            name: 'Clinical Trials Guidance Documents _ FDA.pdf',
            path: 'clinical_studies/Clinical Trials Guidance Documents _ FDA.pdf'
        },
        {
            icon: 'info',
            name: 'CoveredEntitiesChart20160717 (SM606).pdf',
            path: 'clinical_studies/CoveredEntitiesChart20160717 (SM606).pdf'
        },
        {
            icon: 'info',
            name: 'Early-Collaboration-Meetings-Under-the-FDA-Modernization-Act-(FDAMA)--Final-Guidance-for-Industry-and-for-CDRH-Staff Feb2001 (RA201).pdf',
            path: 'clinical_studies/Early-Collaboration-Meetings-Under-the-FDA-Modernization-Act-(FDAMA)--Final-Guidance-for-Industry-and-for-CDRH-Staff Feb2001 (RA201).pdf'
        },
        {
            icon: 'info',
            name: 'Electronic-Source-Data-in-Clinical-Investigations Sep 2013 (DM702).pdf',
            path: 'clinical_studies/Electronic-Source-Data-in-Clinical-Investigations Sep 2013 (DM702).pdf'
        },
        {
            icon: 'info',
            name: 'Estab_and_Operation_Clinical_Trial_Data_Monitoring_Comm_02_2019 (SM605).pdf',
            path: 'clinical_studies/Estab_and_Operation_Clinical_Trial_Data_Monitoring_Comm_02_2019 (SM605).pdf'
        },
        {
            icon: 'info',
            name: 'FDA-3500A_MedWatch AE Mandatory exp 30-Nov2021.pdf',
            path: 'clinical_studies/FDA-3500A_MedWatch AE Mandatory exp 30-Nov2021.pdf'
        },
        {
            icon: 'info',
            name: 'FDA-3500A_MedWatch AE Voluntary exp 30-Nov2021.docx',
            path: 'clinical_studies/FDA-3500A_MedWatch AE Voluntary exp 30-Nov2021.docx'
        },
        {
            icon: 'info',
            name: 'FDAAA 801 and the Final Rule - ClinicalTrials.gov.pdf',
            path: 'clinical_studies/FDAAA 801 and the Final Rule - ClinicalTrials.gov.pdf'
        },
        {
            icon: 'info',
            name: 'Financial Conflict of Interest_ HHS Guidance (2004) _ (GA104).pdf',
            path: 'clinical_studies/Financial Conflict of Interest_ HHS Guidance (2004) _ (GA104).pdf'
        },
        {
            icon: 'info',
            name: 'Financial-Disclosure-by-Clinical-Investigators Feb 2013 (GA104).pdf',
            path: 'clinical_studies/Financial-Disclosure-by-Clinical-Investigators Feb 2013 (GA104).pdf'
        },
        {
            icon: 'info',
            name: 'Frequently-Asked-Questions---IRB-Registration July 2009(SM601).pdf',
            path: 'clinical_studies/Frequently-Asked-Questions---IRB-Registration July 2009(SM601).pdf'
        },
        {
            icon: 'info',
            name: 'Guidance-for-Industry---Using-a-Centralized-IRB-Review-Process-in-Multicenter-Clinical-Trials Mar 2006(SM601).pdf',
            path: 'clinical_studies/Guidance-for-Industry---Using-a-Centralized-IRB-Review-Process-in-Multicenter-Clinical-Trials Mar 2006(SM601).pdf'
        },
        {
            icon: 'info',
            name: 'Guidance-for-Industry--Computerized-Systems-Used-in-Clinical-Investigations May 2007 (DM702).pdf',
            path: 'clinical_studies/Guidance-for-Industry--Computerized-Systems-Used-in-Clinical-Investigations May 2007 (DM702).pdf'
        },
        {
            icon: 'info',
            name: 'Human Subject Regulations Decision Charts - 2018 Requirements.pdf',
            path: 'clinical_studies/Human Subject Regulations Decision Charts - 2018 Requirements.pdf'
        },
        {
            icon: 'info',
            name: 'human-subject-regulations-decision-charts-2018-requirements.pdf',
            path: 'clinical_studies/human-subject-regulations-decision-charts-2018-requirements.pdf'
        },
        {
            icon: 'info',
            name: 'Impact-of-Certain-Provisions-of-Revised-Common-Rule-on-FDA-regulated-Clinical-Trials-Guidance Oct 2018 (SM601).pdf',
            path: 'clinical_studies/Impact-of-Certain-Provisions-of-Revised-Common-Rule-on-FDA-regulated-Clinical-Trials-Guidance Oct 2018 (SM601).pdf'
        },
        {
            icon: 'info',
            name: 'Information Sheet Guidance for Institutional Review Boards (IRBs), Clinical Investigators, and Sponsors _ FDA download Mar 2020.pdf',
            path: 'clinical_studies/Information Sheet Guidance for Institutional Review Boards (IRBs), Clinical Investigators, and Sponsors _ FDA download Mar 2020.pdf'
        },
        {
            icon: 'info',
            name: 'Information Sheet Guidance for IRBs, Clinical Investigators, and Sponsors _ FDA(GA104).pdf',
            path: 'clinical_studies/Information Sheet Guidance for IRBs, Clinical Investigators, and Sponsors _ FDA(GA104).pdf'
        },
        {
            icon: 'info',
            name: 'Investigator-Responsibilities-Protecting the Rights Safety and Welfare of Study Subjects Oct 2009(PM503).pdf',
            path: 'clinical_studies/Investigator-Responsibilities-Protecting the Rights Safety and Welfare of Study Subjects Oct 2009(PM503).pdf'
        },
        {
            icon: 'info',
            name: 'IRB-Continuing-Review-After-Clinical-Investigation-Approval Feb 2012 (SM601).pdf',
            path: 'clinical_studies/IRB-Continuing-Review-After-Clinical-Investigation-Approval Feb 2012 (SM601).pdf'
        },
        {
            icon: 'info',
            name: 'IRB-Responsibilities-for-Reviewing-Quals-of-Investigators-Adequacy-of-Research-Sites-and-Determination-IND-IDE-is-Needed-Aug 2013(RA202-SM601).pdf',
            path: 'clinical_studies/IRB-Responsibilities-for-Reviewing-Quals-of-Investigators-Adequacy-of-Research-Sites-and-Determination-IND-IDE-is-Needed-Aug 2013(RA202-SM601).pdf'
        },
        {
            icon: 'info',
            name: 'IRB-Responsibilities-for-Reviewing-the-Qual-of-Investigators-Adequacy-of-Research-Sites-and-Determination-of-Whether-an-IND Aug2013(RA202).pdf',
            path: 'clinical_studies/IRB-Responsibilities-for-Reviewing-the-Qual-of-Investigators-Adequacy-of-Research-Sites-and-Determination-of-Whether-an-IND Aug2013(RA202).pdf'
        },
        {
            icon: 'info',
            name: 'IRB-Review-of-Stand-Alone-HIPAA-Authorizations-Under-FDA-Regulations(SM606).pdf',
            path: 'clinical_studies/IRB-Review-of-Stand-Alone-HIPAA-Authorizations-Under-FDA-Regulations(SM606).pdf'
        },
        {
            icon: 'info',
            name: 'IRB-Waiver-or-Alteration-of-Informed-Consent-for-Clinical-Investigations-Involving-No-More-Than-Minimal-Risk-to-Human-Subjects-July 2017(SM601).pdf',
            path: 'clinical_studies/IRB-Waiver-or-Alteration-of-Informed-Consent-for-Clinical-Investigations-Involving-No-More-Than-Minimal-Risk-to-Human-Subjects-July 2017(SM601).pdf'
        },
        {
            icon: 'info',
            name: 'Med Dev Data Systems, Med Image Storage Devices, Med Image Communication Devices Sep 2019(DM702).pdf',
            path: 'clinical_studies/Med Dev Data Systems, Med Image Storage Devices, Med Image Communication Devices Sep 2019(DM702).pdf'
        },
        {
            icon: 'info',
            name: 'Oversight-of-Clinical-Investigations-A-Risk-Based-Approach-to-Monitoring Aug 2013(PM503).pdf',
            path: 'clinical_studies/Oversight-of-Clinical-Investigations-A-Risk-Based-Approach-to-Monitoring Aug 2013(PM503).pdf'
        },
        {
            icon: 'info',
            name: 'Part-11 Electronic-Records Electronic Signatures Scope-and-Application-Aug 2003 (DM701-702).pdf',
            path: 'clinical_studies/Part-11 Electronic-Records Electronic Signatures Scope-and-Application-Aug 2003 (DM701-702).pdf'
        },
        {
            icon: 'info',
            name: 'Payment and Reimbursement to Research Subjects _ FDA Jan 2018 (SM602).pdf',
            path: 'clinical_studies/Payment and Reimbursement to Research Subjects _ FDA Jan 2018 (SM602).pdf'
        },
        {
            icon: 'info',
            name: 'Preparing-Notices-of-Availability-of-Investigational-Med-Dev-and-for-Recruiting-Study-Subjects-Guidance Mar 1999 (SM602).pdf',
            path: 'clinical_studies/Preparing-Notices-of-Availability-of-Investigational-Med-Dev-and-for-Recruiting-Study-Subjects-Guidance Mar 1999 (SM602).pdf'
        },
        {
            icon: 'info',
            name: 'Recruiting Study Subjects _ FDA Jan 1998 - Jul 2018 (SM601-602).pdf',
            path: 'clinical_studies/Recruiting Study Subjects _ FDA Jan 1998 - Jul 2018 (SM601-602).pdf'
        },
        {
            icon: 'info',
            name: 'Risk Based Approach to Monitoring Clinical Investigations Q&A March 2019 (SS401) .pdf',
            path: 'clinical_studies/Risk Based Approach to Monitoring Clinical Investigations Q&A March 2019 (SS401) .pdf'
        },
        {
            icon: 'info',
            name: 'Screening Tests Prior to Study Enrollment _ FDA Jan 1998 (SM602-603).pdf',
            path: 'clinical_studies/Screening Tests Prior to Study Enrollment _ FDA Jan 1998 (SM602-603).pdf'
        },
        {
            icon: 'info',
            name: 'Sponsor\'s Responsibilities For Significant Risk Device Investigations Nov1995 (PM501).pdf',
            path: 'clinical_studies/Sponsor\'s Responsibilities For Significant Risk Device Investigations Nov1995 (PM501).pdf'
        },
        {
            icon: 'info',
            name: 'UCM126418 Significant-Risk-and-Nonsignificant-Risk-Medical-Device-Studies---Info-Sheet Jan 2006.pdf',
            path: 'clinical_studies/UCM126418 Significant-Risk-and-Nonsignificant-Risk-Medical-Device-Studies---Info-Sheet Jan 2006.pdf'
        },
        {
            icon: 'info',
            name: 'use-electronic-informed-consent-questions-answers-document Dec 2016 (DM702).pdf',
            path: 'clinical_studies/use-electronic-informed-consent-questions-answers-document Dec 2016 (DM702).pdf'
        },
        {
            icon: 'info',
            name: 'Use-electronic-informed-consent-questions-answers-document Dec 2016(SM601).pdf',
            path: 'clinical_studies/Use-electronic-informed-consent-questions-answers-document Dec 2016(SM601).pdf'
        },
        {
            icon: 'info',
            name: 'Use-of-Electronic-Health-Record-Data-in-Clinical-Investigations-Guidance-for-Industry Jul2018 (DM702).pdf',
            path: 'clinical_studies/Use-of-Electronic-Health-Record-Data-in-Clinical-Investigations-Guidance-for-Industry Jul2018 (DM702).pdf'
        },
        {
            icon: 'info',
            name: 'Use-of-Electronic-Records-and-Electronic-Signatures-in-Clinical-Investigations-Under-21-CFR-Part-11 Jun2017 (DM702).pdf',
            path: 'clinical_studies/Use-of-Electronic-Records-and-Electronic-Signatures-in-Clinical-Investigations-Under-21-CFR-Part-11 Jun2017 (DM702).pdf'
        }
    ];

    templatesBundle3 = [
        {
            icon: 'info',
            name: 'FRM-071 Clinical Protocol Template-00.docx',
            path: 'clinical_studies/FRM-071 Clinical Protocol Template-00.docx'
        },
        {
            icon: 'info',
            name: 'Device Averse Affects - Based on  FDA Medwatch Form.docx',
            path: 'clinical_studies/Device Averse Affects - Based on  FDA Medwatch Form.docx'
        },
    ];

    public formGroup = new FormGroup({
        hasClinicalStudies: new FormControl<boolean>(false),
        hasSensitivityStudies: new FormControl<boolean>(false),
        hasCutoffInformation: new FormControl<boolean>(false),
        hasSupportiveData: new FormControl<boolean>(false),
    });

    private destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService
    ) {
    }

    public ngOnInit(): void {
        // this.formGroup.patchValue(this.submissionService.selectedSubmission.performanceTesting!);
        this.formGroup.valueChanges.subscribe(() => {
            // this.submissionService.selectedSubmission.performanceTesting = this.clinicalStudies;
            // hasClinicalStudies: this.formGroup.controls.hasClinicalStudies.value,
            // clinicalStudiesFiles: this.submissionService.selectedSubmission.performanceTesting.clinicalStudies.clinicalStudiesFiles,
            // hasSensitivityStudies: this.formGroup.controls.hasSensitivityStudies.value,
            // sensitivityStudiesFiles: this.submissionService.selectedSubmission.performanceTesting.clinicalStudies.sensitivityStudiesFiles,
            // hasCutoffInformation: this.formGroup.controls.hasCutoffInformation.value,
            // cutoffInformationFiles: this.submissionService.selectedSubmission.performanceTesting.clinicalStudies.cutoffInformationFiles,
            // hasSupportiveData: this.formGroup.controls.hasSupportiveData.value,
            // supportiveDataFiles: this.submissionService.selectedSubmission.performanceTesting.clinicalStudies.supportiveDataFiles,
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(false, (submission: Submission) => submission)
    }

    public get hasClinicalStudies(): boolean {
        return !!this.formGroup.controls.hasClinicalStudies.value;
    }

    public get hasSensitivityStudies(): boolean {
        return !!this.formGroup.controls.hasSensitivityStudies.value;
    }

    public get hasCutoffInformation(): boolean {
        return !!this.formGroup.controls.hasCutoffInformation.value;
    }

    public get hasSupportiveData(): boolean {
        return !!this.formGroup.controls.hasSupportiveData.value;
    }

    protected get clinicalStudiesFiles(): Set<UploadedFile> {
        return new Set();
    }

    protected uploadClinicalStudiesFiles(files: Set<File>) {
    }

    protected deleteClinicalStudiesFiles(files: Set<File>) {

    }

    protected get sensitivityStudiesFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.clinicSensitivitySpecificityFiles || new Set();
    }

    protected uploadSensitivityStudiesFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'CLINICAL_SENSITIVITY_SPECIFICITY_STUDIES', files)
    }

    protected deleteSensitivityStudiesFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'CLINICAL_SENSITIVITY_SPECIFICITY_STUDIES', files);
    }

    protected get cutoffInformationFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.clinicalCutoffInformationFiles || new Set();
    }

    protected uploadCutoffInformationFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'CLINICAL_CUTOFF_INFORMATION', files)
    }

    protected deleteCutoffInformationFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'CLINICAL_CUTOFF_INFORMATION', files);
    }

    protected get clinicalSupportiveDataFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.clinicalSupportingData || new Set();
    }

    protected uploadClinicalSupportiveDataFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'CLINICAL_SUPPORTING_DATA', files)
    }

    protected deleteClinicalSupportiveDataFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'CLINICAL_SUPPORTING_DATA', files);
    }

    protected get ppoFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.proFiles || new Set<UploadedFile>();
    }

    protected uploadPPOFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'PRO', files);
    }

    protected deletePPOFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'PRO', files);
    }

    protected get ppiFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.ppiFiles || new Set<UploadedFile>();
    }

    protected uploadPPIFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'PPI', files);
    }

    protected deletePPIFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'PPI', files);
    }

    protected get statementOfComplianceUsfiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.statementOfComplianceUsFiles || new Set<UploadedFile>();
    }

    protected uploadStatementOfComplianceUsFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'STATEMENT_OF_COMPLIANCE_US', files);
    }

    protected deleteStatementOfComplianceUsFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'STATEMENT_OF_COMPLIANCE_US', files);
    }

    protected get statementOfComplianceOusFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.statementOfComplianceOusFiles || new Set<UploadedFile>();
    }

    protected uploadStatementOfComplianceOusFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'STATEMENT_OF_COMPLIANCE_OUS', files);
    }

    protected deleteStatementOfComplianceOusFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'STATEMENT_OF_COMPLIANCE_OUS', files);
    }
}

import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { ProductClassification } from "src/app/backend";
import { PredicateDeviceService } from "src/app/services/predicate-device.service";
import { ProductClassificationService } from "src/app/services/product-classification.service";

@Component({
    selector: 'app-product-classification-dialog',
    templateUrl: './product-classification-dialog.component.html',
    styleUrls: ['./product-classification-dialog.component.scss'],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class ProductClassificationDialogComponent implements OnInit {

    productClassification: ProductClassification = {} as ProductClassification;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { productCode: string },
        private predicateDeviceService: PredicateDeviceService,
        private productClassificationService: ProductClassificationService
    ) {
    }

    ngOnInit(): void {
        this.productClassificationService.fetchProductClassifications({productCode: this.data.productCode} as Partial<ProductClassification>)
            .subscribe(productClassificationResponse => {
                console.log(productClassificationResponse);
                this.productClassification = productClassificationResponse?.content?.[0] ?? {} as ProductClassification
            });
    }

    getSubmissionString(submissionType: string): string {
        return this.predicateDeviceService.toSubmissionString(submissionType);
    }
}

import { CommonModule } from "@angular/common";
import { Component, ContentChild, Inject, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MATERIAL_MODULES } from "src/app/material.imports";
import { ResourcesService } from "src/app/services/template/resources.service";


export interface Resource {
    name: string;
    path: string;
    icon: string;
}

@Component({
    selector: 'app-guidance',
    templateUrl: './guidance.component.html',
    styleUrls: ['./guidance.component.scss'],
    imports: [
        CommonModule,
        ...MATERIAL_MODULES
    ]
})
export class GuidanceComponent {

    @Input() resources: Resource[] = [];
    @Input() templates: Resource[] = [];
    @ContentChild(TemplateRef) guidanceContent!: TemplateRef<any>;
    @ViewChild('guidanceTemplate') guidanceTemplate!: TemplateRef<any>;
    @ViewChild('templatesTemplate') templatesTemplate!: TemplateRef<any>;

    constructor(
        private resourcesService: ResourcesService,
        public dialog: MatDialog
    ) {
    }

    public handle(resource: string): string {
        return resource.startsWith('http') ? resource : this.resourcesService.getResourceUrl(resource);
    }

    openGuidance() {
        this.dialog.open(DialogContentDialog, {
            width: '85%',
            data: {
                title: 'Guidance',
                content: this.guidanceTemplate
            }
        });
    }

    openTemplates() {
        this.dialog.open(DialogContentDialog, {
            width: '66%',
            data: {
                title: 'Templates',
                content: this.templatesTemplate
            }
        });
    }
}

@Component({
    selector: 'dialog-content-example-dialog',
    template: `
        <mat-dialog-content class="mat-typography">
            <ng-container *ngTemplateOutlet="data.content"></ng-container>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
        </mat-dialog-actions>
    `,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class DialogContentDialog {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
}

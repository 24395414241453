import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";

import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { MedicalDevice, Submission } from "src/app/backend";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { MedicalDeviceState } from "src/app/models/medicalDeviceState";
import { DeactivationService } from "src/app/services/deactivation.service";
import { SubmissionService } from "src/app/services/submission.service";

import { Observable, Subject, takeUntil } from "rxjs";


@Component({
    selector: 'app-medical-device',
    templateUrl: './medical-device.component.html',
    styleUrls: ['./medical-device.component.scss'],
    imports: [
        CommonModule,
        GuidanceComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        StepComponent
    ]
})
export class MedicalDeviceComponent implements OnInit, OnDestroy, Deactivatable {

    protected readonly MedicalDeviceState = MedicalDeviceState;

    protected resourceBundle = {
        medicalDevice: [
            {
                icon: 'description',
                name: 'How to Determine if Your Product is a Medical Device _ FDA.pdf',
                path: 'medical_device/How to Determine if Your Product is a Medical Device _ FDA.pdf'
            }
        ]
    };

    public formGroup = new FormGroup({
        meetsCriteria1: new FormControl<boolean>(false),
        meetsCriteria2: new FormControl<boolean>(false),
        meetsCriteria3: new FormControl<boolean>(false),
        noCriteriaMet: new FormControl<boolean>(false)
    });

    private destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private submissionService: SubmissionService,
        private deactivationService: DeactivationService,
    ) {
    }

    public ngOnInit(): void {
        this.loadFormData();
        this.registerFormListeners();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(this.formGroup.dirty, (submission: Submission) => this.patchSubmission(submission));
    }

    private loadFormData() {
        this.formGroup.patchValue(this.submissionService.selectedSubmission?.overview?.medicalDevice ?? {});
    }

    private registerFormListeners() {
        // Deselect 'None of the above' if any of the other criteria are selected.
        // We'll use a short-circuit hack to assess and set the value if needed.
        this.formGroup.controls.meetsCriteria1.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isSelected) => isSelected && this.formGroup.controls.noCriteriaMet.setValue(false));
        this.formGroup.controls.meetsCriteria2.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isSelected) => isSelected && this.formGroup.controls.noCriteriaMet.setValue(false));
        this.formGroup.controls.meetsCriteria3.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isSelected) => isSelected && this.formGroup.controls.noCriteriaMet.setValue(false));

        // Deselect all other criteria if 'None of the above' is selected.
        this.formGroup.controls.noCriteriaMet.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isSelected) => {
            if (isSelected) {
                this.formGroup.controls.meetsCriteria1.setValue(false)
                this.formGroup.controls.meetsCriteria2.setValue(false)
                this.formGroup.controls.meetsCriteria3.setValue(false)
            }
        });
    }

    public patchSubmission(submission: Submission): Submission {
        return {
            ...submission,
            overview: {
                ...submission.overview,
                medicalDevice: this.formGroup.value as MedicalDevice,
            }
        } as Submission;
    }

    public get medicalDeviceState(): MedicalDeviceState {
        if (this.formGroup.controls.meetsCriteria1 || this.formGroup.controls.meetsCriteria2 || this.formGroup.controls.meetsCriteria3) {
            return MedicalDeviceState.MEDICAL_DEVICE;
        } else if (this.formGroup.controls.noCriteriaMet) {
            return MedicalDeviceState.NON_MEDICAL_DEVICE;
        } else {
            return MedicalDeviceState.UNKNOWN;
        }
    }
}

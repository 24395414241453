import { CommonModule, CurrencyPipe, KeyValue, KeyValuePipe, NgIf } from "@angular/common";
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { MATERIAL_MODULES } from "src/app/material.imports";


interface StepNode {
    name: string;
    totalHours: number;
};

const STEPS = {
    "Project Planning and Protocol Development": [
        {name: 'Initial Review of Project with Client', totalHours: 3.0},
        {name: 'Internal Team Meeting', totalHours: 3.0},
        {name: 'Project Plan Creation', totalHours: 20.0},
        {name: 'Background Development', totalHours: 40.0},
        {name: 'Statistical Design', totalHours: 80.0},
        {name: 'Development of Inclusion/Exclusion', totalHours: 40.0},
        {name: 'Write Study Protocol - Ver 1', totalHours: 50.0},
        {name: 'Review Protocol Ver 1 with Client', totalHours: 10.0},
        {name: 'Write Study Protocol - Ver 2', totalHours: 15.0},
        {name: 'Review Protocol Ver 2 with Client', totalHours: 7.0},
        {name: 'Write Study Protocol - Ver 3', totalHours: 10.0},
        {name: 'Review Protocol Ver 3 with Client', totalHours: 8.0}
    ],
    "IRB/Regulatory": [
        {name: 'Compile Necessary Docs for IRB Submission', totalHours: 10.0},
        {name: 'IRB/Regulatory Documentation and Follow-up', totalHours: 20.0},
        {name: 'Initial Protocol/ICF Submission/Review/Approval', totalHours: 24.0},
        {name: 'Site/PI Submission/Review/Approval', totalHours: 0.0},
        {name: 'Site/PI Informed Consent Form Submission/Review/Approval', totalHours: 0.0},
        {name: 'Continuing Review of Study - Submission/Review/Approval', totalHours: 0.0},
        {name: 'Continuing Review of Site/PI - Submission/Review/Approval', totalHours: 0.0},
        {name: 'Protocol Amendment (no ICF changes) Submission/Review/Approval', totalHours: 0.0},
        {name: 'Protocol Amendment (ICF changes) Submission/Review/Approval', totalHours: 0.0},
        {name: 'Study Closeout - Study Level - Submission/Review/Acknowledgment', totalHours: 2.0},
        {name: 'Study Closeout - Per PI/Site - Submission/Review/Acknowledgment', totalHours: 8.0}
    ],
    "Site Identification, Screening and Selection": [
        {name: 'Site Screening', totalHours: 0.0},
        {name: 'Site Identification', totalHours: 0.0},
        {name: 'NDA\'s', totalHours: 8.0},
        {name: 'Introductory Call', totalHours: 8.0},
        {name: 'Site Assessment/Qualification', totalHours: 24.0},
        {name: 'Site Tracking Form (per Site)', totalHours: 24.0},
        {name: 'Assessment/Qualification Report', totalHours: 32.0},
        {name: 'Final Site Selection', totalHours: 8.0}
    ],
    "Site/per Patient": [
        {name: 'Site Contract and Budget', totalHours: 40.0},
        {name: 'Site Start-up Fee\'s', totalHours: 0.0},
        {name: 'Site Initiation Presentation Creation', totalHours: 10.0},
        {name: 'Lakeside Binder Creation', totalHours: 8.0},
        {name: 'Lakeside Site Initiation', totalHours: 16.0},
        {name: 'Site Monitoring', totalHours: 64.0},
        {name: 'Number of Patients', totalHours: 0.0},
        {name: 'Number of Patient Tests', totalHours: 0.0}
    ],
    "Lakeside Life Science Project Management": [
        {name: 'Study Coordinator', totalHours: 312.0},
        {name: 'Project Manager', totalHours: 219.0},
        {name: 'Data Management', totalHours: 30.0},
        {name: 'Data Compilation', totalHours: 30.0},
        {name: 'Record Retention', totalHours: 15.0},
        {name: 'Data analysis', totalHours: 30.0},
        {name: 'Clinical Study Report', totalHours: 15.0},
        {name: 'Bi-weekly Status Meetings with Client for length of study', totalHours: 36.0},
        {name: 'Study/Site Accounting Fee', totalHours: 180.0}
    ]
};


@Component({
    selector: 'app-project-planning',
    imports: [
        CommonModule,
        FormsModule,
        GuidanceComponent,
        NgIf,
        ReactiveFormsModule,
        StepComponent,
        ...MATERIAL_MODULES,
        KeyValuePipe
    ],
    providers: [CurrencyPipe],
    templateUrl: './project-planning.component.html',
    styleUrl: './project-planning.component.scss'
})
export class ProjectPlanningComponent {
    protected displayedColumns: string[] = ['name', 'totalHours'];
    protected steps = STEPS;

    private hourlyRate: number = 342.25;

    public formGroup = new FormGroup({
        protocolCount: new FormControl<number>(10, Validators.required),
        labTestingHours: new FormControl<number>(40, Validators.required),
        competitorHourlyRate: new FormControl<number>(400, Validators.required),
    });

    constructor(
        private currencyPipe: CurrencyPipe
    ) {
    }

    protected originalOrder = (a: KeyValue<string,StepNode[]>, b: KeyValue<string,StepNode[]>): number => {
        return 0;
    }

    protected getTotalHours(steps: StepNode[]): number {
        return steps.map(step => step.totalHours).reduce((acc, value) => acc + value, 0);
    }

    protected get costEstimate(): string {
        const protocolCount = this.formGroup.controls.protocolCount.value || 0;
        const labTestingHours = this.formGroup.controls.labTestingHours.value || 0;
        const cost: number = protocolCount * labTestingHours * this.hourlyRate;
        return this.currencyPipe.transform(cost, 'USD', 'symbol', '2.2-2') || '';
    }

    protected get competitorCostEstimate(): string {
        const protocolCount: number = this.formGroup.controls.protocolCount.value || 0;
        const labTestingHours: number = this.formGroup.controls.labTestingHours.value || 0;
        const competitorHourlyRate: number = this.formGroup.controls.competitorHourlyRate.value || 0;
        const cost: number = protocolCount * labTestingHours * competitorHourlyRate;
        return this.currencyPipe.transform(cost, 'USD', 'symbol', '2.2-2') || '';
    }
}

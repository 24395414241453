import { CommonModule, DatePipe } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";

import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { DeviceRegistration, PagePredicateDevice, PredicateDevice, Submission } from "src/app/backend";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { ClassificationPanel } from "src/app/models/classificationPanel";
import { DeactivationService } from "src/app/services/deactivation.service";
import { LoadingService } from "src/app/services/loading-service.service";
import { PredicateDeviceService } from "src/app/services/predicate-device.service";
import { ProductClassificationService } from "src/app/services/product-classification.service";
import { SubmissionService } from "src/app/services/submission.service";

import { catchError, finalize, merge, Observable, of, startWith, Subject, switchMap } from "rxjs";
import {
    ProductClassificationDialogComponent
} from "src/app/components/pages/overview/predicate-device/product-classification-dialog/product-classification-dialog.component";


@Component({
    selector: 'app-predicate-device',
    templateUrl: './predicate-device.component.html',
    styleUrls: ['./predicate-device.component.scss'],
    imports: [
        CommonModule,
        GuidanceComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        ReactiveFormsModule,
        StepComponent
    ]
})
export class PredicateDeviceComponent implements OnInit, AfterViewInit, OnDestroy, Deactivatable {

    @ViewChild(MatSort) private sort = {} as MatSort;
    @ViewChild(MatPaginator) protected paginator = {} as MatPaginator;

    public predicateDevicesDataSource = new MatTableDataSource<PredicateDevice>();
    protected selectedPredicateDeviceDataSource = new MatTableDataSource<PredicateDevice>();
    protected displayedColumns = ['handle', 'deviceName', 'kNumber', 'decisionDate', 'productCode', 'classification', 'reviewAdviseComm', 'actions'];

    protected classificationPanels: ClassificationPanel[] = [];

    protected isLoadingData = true;

    public formGroup = new FormGroup({
        deviceName: new FormControl(''),
        reviewPanel: new FormControl({abbr: null} as unknown as { abbr: string }),
        decisionDate: new FormControl(new Date(2010, 0, 1))
    });

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private deviceService: PredicateDeviceService,
        private productClassificationService: ProductClassificationService,
        private submissionService: SubmissionService,
        private loadingService: LoadingService,
        private dialog: MatDialog,
        private deactivationService: DeactivationService
    ) {
    }

    ngOnInit(): void {
        this.fetchClassificationPanels();
        this.loadFormData();
        this.updateStrategyOnSubmissionChanges();
    }

    ngAfterViewInit(): void {
        this.predicateDevicesDataSource.sort = this.sort;
        this.registerFormListeners();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(true, (submission: Submission) => this.patchSubmission(submission));
    }

    private fetchClassificationPanels(): void {
        this.loadingService.show();
        this.productClassificationService.fetchClassificationPanels$()
            .pipe(
                finalize(() => this.loadingService.hide())
            )
            .subscribe((classificationPanels: ClassificationPanel[]) => {
                this.classificationPanels = classificationPanels;
            });
    }

    private loadFormData() {
        this.selectedPredicateDeviceDataSource.data = this.submissionService.selectedSubmission.overview.predicateDevices;
    }

    private registerFormListeners(): void {
        // If the user changes the sort order, reset back to the first page.
        this.sort!.sortChange.subscribe(() => this.paginator!.firstPage());

        // if any of these change, we need to re-fetch the data.
        merge(
            this.formGroup.get('decisionDate')!.valueChanges,
            this.formGroup.get('deviceName')!.valueChanges,
            this.formGroup.get('reviewPanel')!.valueChanges,
            this.sort!.sortChange,
            this.paginator!.page,
        )
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingData = true;
                    const datePipe = new DatePipe('en-US');
                    const predicateDevice = {
                        deviceName: this.formGroup.controls.deviceName.value ?? '',
                        reviewAdviseComm: this.formGroup.controls.reviewPanel.value?.abbr ?? '',
                        decisionDate: datePipe.transform(this.formGroup.controls.decisionDate.value, 'dd-MM-yyyy') ?? '',
                    }
                    return this.deviceService.fetchPredicateDevices(
                        predicateDevice,
                        this.paginator!.pageIndex,
                        this.paginator!.pageSize,
                        [this.sort!.active && this.sort!.direction ? this.sort!.active + ',' + this.sort!.direction : '']
                    )
                        .pipe(catchError(() => of({} as PagePredicateDevice)));
                }))
            .subscribe((pagePredicateDevice: PagePredicateDevice): void => {
                this.isLoadingData = false;
                this.predicateDevicesDataSource.data = pagePredicateDevice.content ?? [];
                this.paginator.length = pagePredicateDevice.totalElements!;
            });
    }

    public patchSubmission(submission: Submission): Submission {
        return {
            ...submission,
            overview: {
                ...submission.overview,
                predicateDevices: this.selectedPredicateDeviceDataSource.data,
            }
        } as Submission;
    }

    private updateStrategyOnSubmissionChanges(): void {
        const classification = this.submissionService.selectedSubmission.overview?.deviceRegistration?.classificationPanel;
        if (classification) {
            const index = this.classificationPanels?.findIndex(obj => obj.abbr === classification) ?? 0;
            this.formGroup.controls.reviewPanel.setValue(this.classificationPanels[index]);
        }
    }

    public isNotMatchingProductClassification(): boolean {
        const productClassification = this.submissionService.selectedSubmission.overview?.deviceRegistration?.classificationPanel;
        return productClassification != null && productClassification !== this.formGroup.controls.reviewPanel.value?.abbr;
    }

    protected isSelectedPredicateDevice(predicateDevice: PredicateDevice): boolean {
        return this.selectedPredicateDeviceDataSource.data.some(obj => obj.kNumber === predicateDevice.kNumber);
    }

    protected addPredicateDevice(predicateDevice: PredicateDevice): void {
        if (!this.isSelectedPredicateDevice(predicateDevice)) {
            const data: PredicateDevice[] = this.selectedPredicateDeviceDataSource.data;
            this.selectedPredicateDeviceDataSource.data = [...data, predicateDevice];
        }
    }

    protected removePredicateDevice(predicateDevice: PredicateDevice): void {
        const data: PredicateDevice[] = this.selectedPredicateDeviceDataSource.data;
        this.selectedPredicateDeviceDataSource.data = data.filter(obj => obj !== predicateDevice);
    }

    public get deviceRegistration(): DeviceRegistration {
        return this.submissionService.selectedSubmission.overview.deviceRegistration!;
    }

    protected openDeviceSummary(kNumber: string): void {
        this.deviceService.fetchDeviceSummaryUrl(kNumber).subscribe(deviceSummary => {
            if (deviceSummary.url === null) {
                window.alert('No summary available for this device.');
            } else {
                window.open(deviceSummary.url, "_blank");
            }
        });
    }

    protected openDecisionSummary(kNumber: string): void {
        this.deviceService.fetchDecisionSummaryUrl(kNumber).subscribe(decisionSummary => {
            if (decisionSummary.url === null) {
                window.alert('No decision summary available for this device.');
            } else {
                window.open(decisionSummary.url, "_blank");
            }
        });
    }

    protected openProductClassification(productClassification: string): void {
        this.dialog.open(ProductClassificationDialogComponent, {
            width: '80%',
            data: {
                productCode: productClassification
            }
        });
    }
}

import { CommonModule } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { SummaryComponent } from "src/app/components/widgets/summary/summary.component";
import { Filing } from "src/app/models/filing";
import { SubmissionService } from "src/app/services/submission.service";
import { Submission } from "src/app/backend";


@Component({
    selector: 'app-submission-strategy',
    templateUrl: './submission-strategy.component.html',
    styleUrls: ['./submission-strategy.component.scss'],
    imports: [
        CommonModule,
        MatButtonModule,
        StepComponent,
        SummaryComponent
    ]
})
export class SubmissionStrategyComponent implements OnInit {

    protected readonly Filing: typeof Filing = Filing;
    protected filingType: Filing = Filing.NONE;

    constructor(
        private submissionService: SubmissionService
    ) {
    }

    public ngOnInit(): void {
        this.submissionService.getFilingRecommendation$().subscribe(filing => {
            this.filingType = filing;
        });
    }

    protected get submission(): Submission {
        return this.submissionService.selectedSubmission;
    }
}

import { CommonModule } from "@angular/common";
import { Component, Input } from '@angular/core';
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MATERIAL_MODULES } from "src/app/material.imports";

@Component({
    selector: 'app-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss'],
    imports: [
        CommonModule,
        MatCardModule,
        ...MATERIAL_MODULES
    ]
})
export class StepComponent {

    @Input() title: string = '';
}

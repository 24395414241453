import { CommonModule } from "@angular/common";
import { Component } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { DeactivationService } from "src/app/services/deactivation.service";
import { Observable } from "rxjs";
import { DragDropComponent } from "src/app/components/widgets/drag-drop/drag-drop.component";
import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { SubmissionService } from "src/app/services/submission.service";
import { Submission, UploadedFile } from "src/app/backend";

@Component({
    selector: 'app-comparison-studies',
    templateUrl: './comparison-studies.component.html',
    styleUrls: ['./comparison-studies.component.scss'],
    imports: [
        CommonModule,
        DragDropComponent,
        GuidanceComponent,
        MatButtonModule,
        RouterModule,
        StepComponent,
    ]
})
export class ComparisonStudiesComponent implements Deactivatable {

    public constructor(
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService
    ) {
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(false, (submission: Submission) => submission)
    }

    protected get methodComparisonFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.methodComparisonFiles || new Set();
    }

    protected uploadMethodComparisonFiles(files: Set<File>): void {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'METHOD_COMPARISON_STUDY', files);
    }

    protected deleteMethodComparisonFiles(files: Set<File>): void {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'METHOD_COMPARISON_STUDY', files);
    }

    protected get matrixComparisonFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.matrixComparisonFiles || new Set();
    }

    protected uploadMatrixComparisonFiles(files: Set<File>): void {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, 'MATRIX_COMPARISON_STUDY', files);
    }

    protected deleteMatrixComparisonFiles(files: Set<File>): void {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, 'MATRIX_COMPARISON_STUDY', files);
    }
}

import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MATERIAL_MODULES } from "src/app/material.imports";

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    imports: [
        ...MATERIAL_MODULES
    ]
})
export class LoadingComponent {

}

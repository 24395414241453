import { CommonModule } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { RouterModule } from "@angular/router";
import { Subject } from "rxjs";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { COUNTRIES, Country } from "src/app/models/country";
import { State, STATES } from "src/app/models/state";
import { AuthenticationService } from "src/app/services/authentication.service";


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        RouterModule,
        StepComponent,
    ]
})
export class ProfileComponent implements OnInit, OnDestroy {

    private readonly dialog = inject(MatDialog);

    protected readonly STATES: State[] = STATES;
    protected readonly COUNTRIES: Country[] = COUNTRIES;

    protected formGroup = new FormGroup({
        title: new FormControl<string>('', Validators.required),
        lastName: new FormControl<string>('', Validators.required),
        firstName: new FormControl<string>('', Validators.required),
        email: new FormControl<string>('', [Validators.required, Validators.email]),
        phone: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]),
        occupation: new FormControl<string>('', Validators.required),
        companyName: new FormControl<string>('', Validators.required),
        address1: new FormControl<string>('', Validators.required),
        address2: new FormControl<string>(''),
        city: new FormControl<string>('', Validators.required),
        state: new FormControl<string>('', Validators.required),
        zip: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{5}$/)]),
        country: new FormControl<string>('US', Validators.required),
    });

    private destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.formGroup.patchValue(this.authenticationService.profile);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
